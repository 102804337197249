

































































































































































































































// .js-badger-accordion__panel {
//   max-height: 75vh;
//   overflow: hidden;
// }

.js-badger-accordion-header.-ba-is-active {
  .badger-accordion-title,
  .badger-toggle-indicator {
    @apply text-red-600;
  }
}

.js-badger-accordion-header {
  @apply rounded-sm  mt-2 bg-white shadow;
}

.js-badger-accordion--initialized .js-badger-accordion__panel {
  transition: all ease-in-out 0.2s;
}
